import { h } from 'preact'
// import { route } from "preact-router"

const Accordion = ({
  createDescriptionModal,
  title,
  jobType,
  views,
  applications,
  handleOpen
}) => {
  // function handleSubmit(){
  //     route('/redirect')
  // }
  return (
    <label
      htmlFor={createDescriptionModal}
      onClick={handleOpen}
      className='cursor-pointer border p-6 rounded-lg shadow-md min-w-full md:min-w-[20rem]  lg:min-w-[26rem] bg-gray-100 hover:shadow-lg'
    >
      <div className='flex flex-col md:flex-row gap-4 md:items-center pb-2'>
        <div className='font-bold text-xl lg:text-2xl'>{title}</div>
      </div>
      <div className='flex flex-row-reverse justify-between items-center gap-1 pb-4'>
        <div className='flex'>
          <div className='border text-white bg-orange-700 rounded-full px-3 py-1'>
            {jobType}
          </div>
        </div>
        <div className=' text-green-600'>Actively Recruiting</div>
      </div>
      <div className='flex flex-col md:flex-row gap-2 md:gap-10'>
        <div className='font-semibold'>
          {views} view{views === 1 ? '' : 's'}
        </div>
        <div>
          {applications} applicant{applications === 1 ? '' : 's'}
        </div>
      </div>
    </label>
  )
}
export default Accordion
