import { route } from "preact-router";

function CompanyHeader({ children, name }) {
  function handleLogout() {
    if (typeof window !== `undefined`) {
      window.localStorage.removeItem("interntag-access-token");
      route("/");
    }
  }
  return (
    <div className="drawer drawer-end">
      <input id="company-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        <div className="navbar w-full bg-base-200 z-10 fixed shadow-md flex justify-between px-4">
          <a href="/company/dashboard" className="mx-2 px-2">
            <img src="/assets/Intern-tag.png" width={90} height={10} />
          </a>
          <div className="flex-none">
            <div className="text-xl font-semibold">{name}</div>
            <label
              htmlFor="company-drawer"
              className="btn-ghost btn-square btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block h-6 w-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </label>
          </div>
        </div>
        {children}
      </div>
      <div className="drawer-side">
        <label htmlFor="company-drawer" className="drawer-overlay" />
        <ul className="menu w-60 md:w-80 bg-base-100 p-4">
          <li>
            <a href="/company/dashboard">Dashboard</a>
          </li>
          <li>
            <a href="/company/create-job">Create an AD</a>
          </li>
          <li>
            <a href="/company/profile">Profile</a>
          </li>
          <li>
            <button type="button" onClick={handleLogout}>
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CompanyHeader;
