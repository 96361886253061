import { h } from "preact";

const NoDataScreen = ({ CreateCampaigns, title, content, link }) => {
  return (
    <div>
      <div>
        <img
          src="/assets/noData.svg"
          alt=""
          className="mx-auto p-[6px] flex aspect-square w-[150px] h-[150px] md:w-[250px] md:h-[250px]"
        />
      </div>
      <h3 className="text-center mb-3 md:text-4xl">{title}</h3>
      <div>
        <p className="text-center">{content}</p>
        <label htmlFor={CreateCampaigns}>
          <p className="text-center text-[#01936D] underline cursor-pointer">
            <span className="text-base">{link}</span>
          </p>
        </label>
      </div>
    </div>
  );
};

export default NoDataScreen;
