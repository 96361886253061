import { tryit } from 'radash'

// const ENDPOINT = "http://localhost:3000/api/v1/event";
const ENDPOINT = 'https://intern-tag-backend.onrender.com/api/v1/event'
// const ACTION_ENDPOINT = 'http://localhost:3000/api/v1/action/'
const ACTION_ENDPOINT = 'https://api.interntag.com/api/v1/action'

export async function sendEvent (source = '', message = {}) {
  // attach accessToken
  const accessToken =
    typeof window !== `undefined`
      ? window.localStorage.getItem('interntag-access-token')
      : ''
  message.accessToken = accessToken || ''
  const [err, response] = await tryit(postData)(ENDPOINT, {
    source,
    message
  })
  console.log('error', err)
  console.log('result', response)
  if (!err) {
    return response
  }
}

export async function initiateAction (name = '', data = {}, withAuth = true) {
  // attach accessToken
  const accessToken =
    typeof window !== `undefined`
      ? window.localStorage.getItem('interntag-access-token')
      : ''
  console.log('with auth', withAuth)
  if (withAuth && accessToken) {
    data.accessToken = accessToken
  }
  const [err, response] = await tryit(postData)(ACTION_ENDPOINT, {
    name,
    data
  })
  console.log('error', err)
  console.log('result', response)
  if (!err) {
    return response
  }
}

async function postData (url = '', message = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(message)
  })
  return response.json() // parses JSON response into native JavaScript objects
}
