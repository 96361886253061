import { useSignal, useSignalEffect } from "@preact/signals";
import { route } from "preact-router";
import { initiateAction } from "../services/interntag-api";
import Header from "./company-header";

function CompanyLayout({ children }) {
  const authenticated = useSignal(false);
  const name = useSignal("");

  useSignalEffect(() => {
    console.log("running effect");
    checkAuth();
  });

  async function checkAuth() {
    console.log("auth");
    const accessToken =
      typeof window !== `undefined`
        ? window.localStorage.getItem("interntag-access-token")
        : "";
    if (!accessToken) {
      route("/");
      return;
    }
    const { ok, data } = await initiateAction("user-auth", { accessToken });
    if (!ok) {
      route("/");
      return;
    }
    authenticated.value = true;
    name.value = data.name;
    return;
  }

  return (
    <Header name={name.value}>
      {authenticated.value === true && children}
    </Header>
  );
}

export default CompanyLayout;
