import { format } from "date-fns";
import { h } from "preact";

const Card = ({
  jobId,
  companyDescription,
  showDetails,
  title,
  location,
  country,
  companyName,
  createdAt,
  jobType,
  bookmarked,
  handleBookmarkToggle,
  handleActivityRecord,
  applied,
  applicationDate,
}) => {
  return (
    <div className="border bg-white p-6 rounded-lg shadow-md min-w-full md:min-w-[300px]  lg:min-w-[400px]">
      <div className="flex items-start justify-between">
        <label
          htmlFor={companyDescription}
          className=" cursor-pointer flex flex-col md:flex-row gap-4 md:items-center pb-6"
        >
          <div className="flex">
            <div className="bg-gray-400 text-white rounded-md p-4">log</div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="font-bold text-black hover:underline">
              {companyName}
            </div>
            <div className="text-black">{`${location}, ${country}`}</div>
          </div>
        </label>
        <button
          title={`Save ${title}`}
          className={`w-6 ${
            bookmarked
              ? "fill-black hover:fill-black"
              : "fill-white hover:fill-black"
          } stroke-black`}
          onClick={() => {
            handleBookmarkToggle(jobId, bookmarked);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth={10}
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 363 511.49"
          >
            <path d="M46.73 0h256.78c16.33 0 31.2 6.71 41.98 17.48C356.29 28.29 363 43.17 363 59.5v443.54c0 4.66-3.79 8.45-8.45 8.45a8.41 8.41 0 01-5.42-1.96L181.42 378.99 13.64 509.68c-3.66 2.85-8.97 2.21-11.82-1.46a8.365 8.365 0 01-1.78-5.18L0 46.73c0-12.82 5.25-24.5 13.72-32.98C22.25 5.24 33.92 0 46.73 0zm138.05 112.91l17.21 40.27 43.63 3.9c3.12.25 4.31 4.18 2 6.16l-33.02 28.82 9.76 42.73c.73 3.09-2.79 5.41-5.36 3.7l-37.52-22.42-37.63 22.51c-2.64 1.61-5.9-.8-5.2-3.79l9.76-42.73-33.02-28.82c-2.37-2.04-1.07-5.92 2.15-6.1l43.52-3.91 17.22-40.32c1.2-2.89 5.28-2.78 6.5 0zm-3.28-50.87c31.14 0 59.38 12.64 79.82 33.09 20.44 20.41 33.09 48.64 33.09 79.81s-12.65 59.41-33.07 79.84c-20.43 20.43-48.67 33.07-79.84 33.07-31.18 0-59.4-12.64-79.83-33.07-20.43-20.46-33.08-48.7-33.08-79.84 0-31.13 12.64-59.35 33.08-79.8 20.47-20.47 48.69-33.1 79.83-33.1z" />
          </svg>
        </button>
      </div>
      <label
        htmlFor={showDetails}
        className="cursor-pointer text-black font-semibold"
      >
        {title}
      </label>
      <div className="pt-6 text-green-600">
        {applied
          ? `You applied on ${format(
              new Date(applicationDate),
              "do MMM, yyyy"
            )}`
          : ""}
      </div>
      <div className="text-xs pb-6 text-gray-500 font-semibold uppercase">
        Posted {format(new Date(createdAt), "do MMM, yyyy")}
      </div>
      <div className="pb-6 text-black">Position - {jobType}</div>
      <label
        htmlFor={showDetails}
        onClick={() => {
          if (handleActivityRecord) {
            handleActivityRecord(jobId, "view");
          }
        }}
        className="cursor-pointer bg-black text-white rounded-xl py-2 px-4 w-full"
      >
        Show Details
      </label>
    </div>
  );
};
export default Card;
