import Card from "../../components/card";
import StudentHeader from "../../dependencies/student-header";
// import NoDataScreen from "../components/noData";
import { useSignal, useSignalEffect } from "@preact/signals";
import { initiateAction } from "../../services/interntag-api";
import toast, { Toaster } from "react-hot-toast";

function Bookmarks() {
  const jobs = useSignal([]);

  async function loadData() {
    console.log("running");
    const { ok, data } = await initiateAction("list-all-bookmarks");
    if (ok) {
      jobs.value = data;
    }
  }

  useSignalEffect(() => {
    loadData();
  });

  async function handleBookmarkToggle(jobId, currentStatus) {
    if (currentStatus) {
      const { ok, message } = await initiateAction("remove-bookmarked-job", {
        jobId,
      });
      if (ok) {
        toast.success(message, {
          position: "top-right",
        });
      }
      await loadData();
      return;
    }

    const { ok, message } = await initiateAction("bookmark-job", {
      jobId,
    });
    if (ok) {
      toast.success(message, {
        position: "top-right",
      });
    }
    await loadData();
  }

  async function handleActivityRecord(jobId, activity) {
    if (activity && activity === "view") {
      await initiateAction("record-view-job", {
        jobId,
      });
    }
    if (activity && activity === "apply") {
      await initiateAction("record-apply-job", {
        jobId,
      });
    }
  }

  return (
    <main>
      <div className=" w-full relative min-h-screen lg:bg-gradient-to-b from-white via-white to-gray-200">
        <StudentHeader>
          <div>
            <div className=" ">
              <div className="hidden md:block ">
                <div className="flex fixed top-0 justify-center w-full">
                  <div className="bg-gray-200 opacity-50 rounded-bl-lg rounded-br-lg px-10 pb-10 flex" />
                </div>
                <div className="flex fixed top-20 right-5 ">
                  <div className="bg-gray-200 opacity-50 rounded-full p-5 flex" />
                </div>
                <div className="flex fixed bottom-40 left-5 ">
                  <div className="bg-gray-200 opacity-50 rounded-lg p-7 flex" />
                </div>
              </div>
              <div className="pt-20" />
              <div className="flex px-10 md:px-14 lg:px-20 py-4">
                <div
                  className={` border px-6 py-1 rounded-full text-white bg-[#01936D] hover:bg-[#0E4D40] hover:text-white`}
                >
                  Bookmarks
                </div>
              </div>
            </div>
            <div className="flex flex-wrap md:justify-center items-center gap-6 px-10 pt-4 lg:px-20 pb-10">
              {jobs.value.map((job) => (
                <Card
                  key={job.job_id}
                  title={job.title}
                  location={job.location}
                  createdAt={job.created_at}
                  country={job.country}
                  companyName={job.Company.name}
                  jobType={job.job_type}
                  companyDescription={`company-description-${job.job_id}`}
                  showDetails={`show-details-${job.job_id}`}
                  bookmarked={true}
                  jobId={job.job_id}
                  handleBookmarkToggle={handleBookmarkToggle}
                  handleActivityRecord={handleActivityRecord}
                />
              ))}
            </div>
            {/* Modal Start for show details*/}
            {jobs.value.map((job) => (
              <div key={job.job_id}>
                <input
                  type="checkbox"
                  id={`show-details-${job.job_id}`}
                  className="modal-toggle"
                />
                <label
                  htmlFor={`show-details-${job.job_id}`}
                  className="modal cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x-lg fixed top-0 right-0 fill-white mx-5 my-2 hover:fill-red-500"
                    xlinkTitle="Close"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                  <label
                    className="modal-box w-[91%] max-w-[90%] relative"
                    htmlFor=""
                  >
                    <div className="px-[10%] py-6">
                      <div className="text-2xl font-semibold">{job.title}</div>
                      <div className="">Type: {job.job_type}</div>
                      <div className="">Company: {job.Company.name}</div>
                      <div className="">Location: {job.location}</div>
                      {/* <div className="">Role: {job.job_role}</div> */}
                      <div className="">Date Published: {job.created_at}</div>
                      <div className="text-lg font-semibold pt-10">
                        Description
                      </div>
                      <div className="">{job.description}</div>
                      <div className="text-lg font-semibold pt-10">
                        Requirements
                      </div>
                      <div className="">{job.instruction}</div>
                      <div className="flex flex-col md:flex-row justify-center py-10 gap-4">
                        <a
                          href={job.apply}
                          onClick={() => {
                            handleActivityRecord(job.job_id, "apply");
                          }}
                          target="__blank"
                          className="bg-[#01936D] text-center hover:bg-[#0E4D40] px-6 py-2 text-white rounded-md"
                        >
                          Apply
                        </a>
                      </div>
                    </div>
                  </label>
                </label>
              </div>
            ))}

            {/* Modal Start for about company */}
            {jobs.value.map((job) => (
              <div key={job.job_id}>
                <input
                  type="checkbox"
                  id={`company-description-${job.job_id}`}
                  className="modal-toggle"
                />
                <label
                  htmlFor={`company-description-${job.job_id}`}
                  className="modal cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x-lg fixed top-0 right-0 fill-white mx-5 my-2 hover:fill-red-500"
                    xlinkTitle="Close"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                  <label
                    className="modal-box w-[91%] max-w-[90%] relative"
                    htmlFor=""
                  >
                    <div className="flex flex-col md:flex-row gap-4 lg:gap-10 px-[10%] md:px-0 lg:px-[10%] py-10">
                      <div className=" ">
                        <div className="text-2xl font-semibold">
                          {job.Company.name}
                        </div>
                        <div className="border-b border-gray-300 pb-4">
                          <div className="text-lg font-semibold pt-10">
                            Description
                          </div>
                          <div className="">{job.Company.description}</div>
                        </div>
                        <div className="border-b border-gray-300 pb-4">
                          <div className="text-lg font-semibold pt-10">
                            Jobs
                          </div>
                          {/* <div className="">
                            ViewssnaVs Ghanws GhanaViews Ghana
                          </div> */}
                        </div>
                      </div>
                      <div className="md:border-l border-gray-300">
                        <div className="md:p-5 lg:p-10 md:min-w-max p-2 min-w-fit">
                          <div className="font-semibold uppercase">
                            ABOUT {job.Company.name}
                          </div>
                          {/* <div>Website: openfabric.ai</div> */}
                          {/* <div>Email: openfabric.ai@gmail.com</div> */}
                          <div>Location: {job.Company.location}</div>
                        </div>
                      </div>
                    </div>
                  </label>
                </label>
              </div>
            ))}
          </div>
          <Toaster />
        </StudentHeader>
      </div>
    </main>
  );
}

export default Bookmarks;
