import { useComputed, useSignal, useSignalEffect } from "@preact/signals";
import toast, { Toaster } from "react-hot-toast";
import { initiateAction } from "../../services/interntag-api";
import { route } from "preact-router";
import { format, subYears } from "date-fns";

function StudentProfileEditForm() {
  const studentName = useSignal("");
  const industry = useSignal("");
  const school = useSignal("");
  const course = useSignal("");
  const email = useSignal("");
  const phoneNumber = useSignal("");
  const gender = useSignal("");
  const dob = useSignal("");
  const interest = useSignal("");
  const loading = useSignal(false);
  const maxDOB = format(subYears(new Date(Date.now()), 10), "yyyy-MM-dd");

  const editStudentRequest = useComputed(() => ({
    email: email.value,
    studentName: studentName.value,
    industry: industry.value,
    school: school.value,
    course: course.value,
    phone: phoneNumber.value,
    gender: gender.value,
    dob: dob.value,
    interest: interest.value,
  }));

  async function getProfile() {
    const { ok, data } = await initiateAction("view-profile-student", {}, true);
    console.log(ok, data);
    if (ok) {
      studentName.value = data.Student.name;
      industry.value = data.Student.industry;
      school.value = data.Student.school;
      course.value = data.Student.course;
      phoneNumber.value = data.Student.phone;
      gender.value = data.Student.gender;
      interest.value = data.Student.interest;
      email.value = data.email;
      console.log("... dob", data.Student.dob);
      if (data.Student.dob) {
        dob.value = format(new Date(data.Student.dob), "yyyy-MM-dd");
      }
    }
    // dispose();
  }

  console.log("dob", dob.value);

  useSignalEffect(() => {
    console.log("running effect");
    getProfile();
  });

  function handleBack() {
    route("/student/dashboard");
  }

  async function handleSubmit() {
    const accessToken =
      typeof window !== `undefined`
        ? window.localStorage.getItem("interntag-access-token")
        : "";
    console.log(editStudentRequest.value);
    loading.value = true;

    const result = await initiateAction("edit-student", {
      ...editStudentRequest.value,
      accessToken,
    });
    loading.value = false;
    if (result) {
      console.log(result);
      if (result.ok) {
        toast.success(result.message, {
          position: "top-right",
        });
        getProfile();
      } else {
        toast.error(result.message, {
          position: "top-right",
        });
      }
    }
  }

  return (
    <div className="container mx-auto py-4 px-8 md:px-10">
      <form>
        <div className="flex w-full flex-col md:flex-row gap-5">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="">
              <label>Name</label>
              <input
                type="text"
                placeholder="Company Name"
                value={studentName.value}
                onInput={(e) => (studentName.value = e.target.value)}
                className="my-3 border w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Industry</label>
              <input
                type="text"
                placeholder="Industry"
                value={industry.value}
                onInput={(e) => (industry.value = e.target.value)}
                className="my-3 border w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>School</label>
              <input
                type="text"
                placeholder="School"
                value={school.value}
                onInput={(e) => (school.value = e.target.value)}
                className="my-3 border w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Course</label>
              <input
                type="text"
                placeholder="Course"
                value={course.value}
                onInput={(e) => (course.value = e.target.value)}
                className="my-3 border w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Interest</label>
              <input
                type="text"
                placeholder="Interest"
                value={interest.value}
                onInput={(e) => (interest.value = e.target.value)}
                className="my-3 border w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Gender</label>
              <input
                type="text"
                placeholder="Gender"
                value={gender.value}
                onInput={(e) => (gender.value = e.target.value)}
                className="my-3 border w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Date of Birth</label>
              <input
                required
                type="date"
                placeholder=""
                max={maxDOB}
                value={dob.value}
                onInput={(e) => (dob.value = e.target.value)}
                className="my-3 border w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Phone</label>
              <input
                type="text"
                placeholder="Phone"
                value={phoneNumber.value}
                readOnly={true}
                onInput={(e) => (phoneNumber.value = e.target.value)}
                className="my-3 border w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Email</label>
              <input
                type="text"
                placeholder="Email"
                value={email.value}
                readOnly={true}
                onInput={(e) => (email.value = e.target.value)}
                className="my-3 border w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>

            <div className="flex items-end justify-end">
              <div className="flex justify-end items-baseline gap-4">
                <button
                  disabled={loading.value === true}
                  type="button"
                  onClick={handleBack}
                  className={`mt-3 rounded-md mb-4 ${
                    loading.value === true
                      ? "bg-gray-300"
                      : "bg-gray-700 hover: hover:bg-gray-500"
                  } py-2 px-6 text-xl text-white ring-1 hover:text-white hover:bg-[#01936D] ring-white font-semibold`}
                >
                  Go Back
                </button>
                <button
                  disabled={loading.value === true}
                  type="button"
                  onClick={handleSubmit}
                  className={`mt-3 rounded-md mb-4 ${
                    loading.value === true
                      ? "bg-gray-300"
                      : "bg-[#0E4D40] hover: hover:bg-[#01936D]"
                  } py-2 px-6 text-xl text-white ring-1 hover:text-white hover:bg-[#01936D] ring-white font-semibold`}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Toaster />
    </div>
  );
}

export default StudentProfileEditForm;
