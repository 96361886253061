import { useComputed, useSignal, useSignalEffect } from "@preact/signals";
import toast, { Toaster } from "react-hot-toast";
import { initiateAction } from "../../services/interntag-api";
import { route } from "preact-router";
import { format, subDays } from "date-fns";

function CompanyProfileEditForm() {
  const companyName = useSignal("");
  const industry = useSignal("");
  const country = useSignal("");
  const location = useSignal("");
  const email = useSignal("");
  const description = useSignal("");
  const startDate = useSignal("");
  const maxStartDate = format(subDays(new Date(Date.now()), 1), "yyyy-MM-dd");
  const loading = useSignal(false);

  const editCompanyRequest = useComputed(() => ({
    email: email.value,
    companyName: companyName.value,
    industry: industry.value,
    country: country.value,
    location: location.value,
    description: description.value,
    startDate: startDate.value,
  }));

  async function getProfile() {
    const { ok, data } = await initiateAction("view-profile-company", {}, true);
    console.log(ok, data);
    if (ok) {
      companyName.value = data.Company.name;
      industry.value = data.Company.industry;
      country.value = data.Company.country;
      location.value = data.Company.location;
      description.value = data.Company.description;
      email.value = data.email;
      if (data.startDate) {
        startDate.value = format(new Date(data.startDate), "yyyy-MM-dd");
      }
    }
    // dispose();
  }

  useSignalEffect(() => {
    console.log("running effect");
    toast.remove();
    getProfile();
  });

  function handleBack() {
    route("/company/dashboard");
  }

  async function handleSubmit() {
    const accessToken =
      typeof window !== `undefined`
        ? window.localStorage.getItem("interntag-access-token")
        : "";
    console.log(editCompanyRequest.value);
    loading.value = true;

    const result = await initiateAction("update-company", {
      ...editCompanyRequest.value,
      accessToken,
    });
    loading.value = false;
    if (result) {
      console.log(result);
      if (result.ok) {
        toast.success(result.message, {
          position: "top-right",
        });
        getProfile();
      }
    }
  }

  return (
    <div className="container mx-auto p-4 md:px-10">
      <form>
        <Toaster />
        <div className="flex w-full flex-col md:flex-row gap-5">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="">
              <label>Name of Company</label>
              <input
                type="text"
                placeholder="Company Name"
                value={companyName.value}
                onInput={(e) => (companyName.value = e.target.value)}
                className="my-3 w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Industry</label>
              <input
                type="text"
                placeholder="Industry"
                value={industry.value}
                onInput={(e) => (industry.value = e.target.value)}
                className="my-3 w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Country</label>
              <input
                type="text"
                placeholder="Country"
                value={country.value}
                onInput={(e) => (country.value = e.target.value)}
                className="my-3 w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Location</label>
              <input
                type="text"
                placeholder="Location"
                value={location.value}
                onInput={(e) => (location.value = e.target.value)}
                className="my-3 w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Email</label>
              <input
                type="text"
                placeholder="Email"
                value={email.value}
                readOnly={true}
                onInput={(e) => (email.value = e.target.value)}
                className="my-3 w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Website</label>
              <input
                type="text"
                placeholder="Country"
                value={country.value}
                onInput={(e) => (country.value = e.target.value)}
                className="my-3 w-full rounded-md bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Started Operations</label>
              <input
                required
                type="date"
                placeholder=""
                max={maxStartDate}
                value={startDate.value}
                onInput={(e) => (startDate.value = e.target.value)}
                className="my-3 w-full rounded-lg bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
              />
            </div>
            <div className="">
              <label>Company Description</label>
              <textarea
                className="my-3 w-full rounded-lg bg-transparent py-4  px-4 text-sm ring-2 ring-gray-400"
                onInput={(e) => (description.value = e.target.value)}
                rows="5"
                placeholder="Company Description"
              >
                {description.value}
              </textarea>
            </div>

            <div className="flex items-end justify-end">
              <div className="flex justify-end items-baseline gap-4">
                <button
                  disabled={loading.value === true}
                  type="button"
                  onClick={handleBack}
                  className={`mt-3 rounded-md mb-4 ${
                    loading.value === true
                      ? "bg-gray-300"
                      : "bg-gray-700 hover: hover:bg-gray-500"
                  } py-2 px-6 text-xl text-white ring-1 hover:text-white hover:bg-[#01936D] ring-white font-semibold`}
                >
                  Go Back
                </button>
                <button
                  disabled={loading.value === true}
                  type="button"
                  onClick={handleSubmit}
                  className={`mt-3 rounded-md mb-4 ${
                    loading.value === true
                      ? "bg-gray-300"
                      : "bg-[#0E4D40] hover: hover:bg-[#01936D]"
                  } py-2 px-6 text-xl text-white ring-1 hover:text-white hover:bg-[#01936D] ring-white font-semibold`}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CompanyProfileEditForm;
