import { h } from "preact";

const Pagination = ({
  recordsPerPage,
  totalRecords,
  paginate,
  currentPage,
  onPreviousClick,
  onNextClick,
  maxPageNumberLimit,
  minPageNumberLimit,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRecords / (recordsPerPage || 1)); i++) {
    pageNumbers.push(i);
  }

  if (!totalRecords) return null;

  console.log("page numbers", pageNumbers);
  console.log("current page", currentPage);

  return (
    <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-2 px-10 overflow-x-auto hide-scrollbar">
      <div className="flex items-center gap-2">
        <button
          className={` ${
            currentPage === pageNumbers[0]
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }`}
          disabled={currentPage === pageNumbers[0]}
          onClick={() => onPreviousClick && onPreviousClick()}
        >
          Prev
        </button>
        <ul className="flex w-fit m-auto md:m-0">
          {pageNumbers.map((number) => {
            // console.log("Number", number)
            // console.log("currentPage", currentPage)
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  className={`border border-gray-300 py-1 px-3 cursor-pointer ${
                    currentPage === number ? "bg-black" : "bg-white"
                  }`}
                  onClick={() => {
                    paginate && paginate(number);
                  }}
                >
                  <div
                    className={`${currentPage === number ? "text-white" : ""}`}
                  >
                    {number}
                  </div>
                </li>
              );
            }
          })}
        </ul>
        <button
          className={` ${
            currentPage === pageNumbers[pageNumbers.length - 1]
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }`}
          onClick={() => onNextClick && onNextClick()}
          disabled={currentPage === pageNumbers[pageNumbers.length - 1]}
        >
          Next
        </button>
      </div>
      <div className="text-center lg:text-left mb-1 lg:mb-0 flex gap-2 items-center">
        Page{" "}
        <div className="border border-gray-300 bg-white px-3 py-1">
          {" "}
          {currentPage}{" "}
        </div>{" "}
        of {pageNumbers.length}{" "}
      </div>
    </div>
  );
};

export default Pagination;
