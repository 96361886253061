import { useSignal, useSignalEffect } from "@preact/signals";
import { route } from "preact-router";
import { initiateAction } from "../services/interntag-api";

function StudentHeader({
  children,
  withSearch = true,
  handleSearch = () => false,
}) {
  function handleLogout() {
    if (typeof window !== `undefined`) {
      window.localStorage.removeItem("interntag-access-token");
      route("/");
    }
  }

  const authenticated = useSignal(false);
  const query = useSignal("");

  useSignalEffect(() => {
    console.log("running effect");
    checkAuth();
  });

  async function checkAuth() {
    console.log("auth");
    const accessToken =
      typeof window !== `undefined`
        ? window.localStorage.getItem("interntag-access-token")
        : "";
    if (!accessToken) {
      route("/");
      return;
    }
    const { ok } = await initiateAction("user-auth", { accessToken });
    if (!ok) {
      route("/");
      return;
    }
    authenticated.value = true;
    return;
  }

  return (
    <div className="drawer drawer-end">
      <input id="student-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        <div className="navbar w-full bg-base-200 z-10 fixed shadow-md flex justify-between px-4">
          <a href="/" className="mx-2 px-2">
            <img src="/assets/Intern-tag.png" width={90} height={10} />
          </a>
          {withSearch && (
            <div className="md:flex justify-center hidden">
              <div className="flex bg-white border-2 border-gray-200 rounded-lg mx-4 items-center min-w-full">
                <input
                  className="p-2 w-full appearance-none outline-none "
                  type="text"
                  value={query.value}
                  onInput={(e) => (query.value = e.target.value)}
                  placeholder="Search"
                />
                <button
                  className="hover:bg-[#01936D] p-3 bg-[#0E4D40] rounded-r-md"
                  onClick={() => handleSearch(query.value)}
                >
                  <img
                    src="/assets/search.svg"
                    alt="avatar"
                    className="w-6 h-6"
                  />
                </button>
              </div>
            </div>
          )}

          <div className="flex-none">
            <label
              htmlFor="student-drawer"
              className="btn-ghost btn-square btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block h-6 w-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </label>
          </div>
        </div>
        {children}
      </div>
      <div className="drawer-side">
        <label htmlFor="student-drawer" className="drawer-overlay" />
        <ul className="menu w-60 md:w-80 bg-base-100 p-4">
          <li>
            <a href="/student/dashboard">Dashboard</a>
          </li>
          <li>
            <a href="/student/trending">Trending</a>
          </li>
          <li>
            <a href="/student/recommended">Recommended</a>
          </li>
          <li>
            <a href="/student/bookmarks">Bookmarks</a>
          </li>
          <li>
            <a href="/student/profile">Profile</a>
          </li>
          <li>
            <button type="button" onClick={handleLogout}>
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default StudentHeader;
