import CompanyLayout from "../../dependencies/company-layout";
import CreateScholarshipForm from "../../features/company/create-scholarship-form";

function CreateScholarship() {
  return (
    <main>
      <CompanyLayout>
        <div
          style={{
            backgroundImage: `linear-gradient(rgba(78, 77, 77, 0.8), rgba(78, 77, 77, 0.8)),url("/assets/scholarship.jpg")`,
          }}
          className=" w-full relative bg-fixed bg-center bg-cover bg-no-repeat"
        >
          <div className="flex flex-col justify-center items-center min-h-screen pt-20">
            <h1 className="py-4 text-white text-3xl">Upload a Scholarship</h1>
            <CreateScholarshipForm />
          </div>
        </div>
      </CompanyLayout>
    </main>
  );
}

export default CreateScholarship;
