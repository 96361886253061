import CompanyLayout from "../../dependencies/company-layout";
import CompanyProfileEditForm from "../../features/company/profile-edit";

function CompanyProfile() {
  return (
    <main>
      <CompanyLayout>
        <div className="pt-20" />
        <div className=" w-full relative">
          <div className="lg:grid lg:grid-cols-5 min-h-screen">
            <img
              className="w-full hidden lg:block h-full bg-cover bg-fixed bg-no-repeat bg-center lg:col-span-3"
              src="/assets/company-profile.jpg"
            />

            <div className="w-full lg:col-span-2">
              <h1 className="py-4  text-center text-2xl pt-10">
                Company Profile
              </h1>
              <CompanyProfileEditForm />
              <div className="hidden md:block">
                <div className="flex fixed top-0 justify-center w-full">
                  <div className="bg-[#01936D] opacity-50 rounded-bl-lg rounded-br-lg px-10 pb-10 flex" />
                </div>
                <div className="flex fixed top-80 right-5 ">
                  <div className="bg-[#01936D] opacity-50 rounded-full p-5 flex" />
                </div>
                <div className="flex fixed bottom-40 left-5 ">
                  <div className="bg-gray-200 opacity-50 rounded-lg p-7 flex" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CompanyLayout>
    </main>
  );
}

export default CompanyProfile;
