import { useSignal, useComputed } from "@preact/signals";
import { sendEvent } from "../../services/interntag-api";
import toast, { Toaster } from "react-hot-toast";
import { route } from "preact-router";

function CreateScholarshipForm() {
  const title = useSignal("");
  const scholarshipType = useSignal("");
  const category = useSignal("");
  const program = useSignal("");
  const country = useSignal("");
  const institution = useSignal("");
  const description = useSignal("");
  const period = useSignal("");
  const fee = useSignal("");
  const loading = useSignal(false);

  const periods = ["3 days", "1 week", "1 month"];
  const fees = ["5GHS", "10GHS", "35GHS"];

  const addScholarshipRequest = useComputed(() => ({
    title: title.value,
    scholarshipType: scholarshipType.value,
    category: category.value,
    program: program.value,
    country: country.value,
    institution: institution.value,
    description: description.value,
    period: period.value,
  }));

  function handleBack(){
    route("/");
      }

  async function handleSubmit() {
    console.log(addScholarshipRequest.value);
    loading.value = true;

    const result = await sendEvent(
      "add-scholarship",
      addScholarshipRequest.value,
    );
    loading.value = false;
    if (result) {
      console.log(result);
      if (result.ok) {
        toast.success(result.message, {
          position: "top-right",
        });
        setTimeout(() => {
          route("/");
        }, 2000);
      } else {
        toast.error(result.message, {
          position: "top-right",
        });
      }
    }
  }

  function handlePeriod(e) {
    period.value = e.target.value;
    fee.value = fees[e.target.selectedIndex - 1];
  }

  return (
    <div className="container mx-auto p-2">
      <form>
        <div className="flex w-full flex-col md:flex-row justify-evenly gap-5">
        <div className="w-full md:w-1/2">
        <div>
          <input
            type="text"
            placeholder="Scholarship Title"
            value={title.value}
            onInput={(e) => (title.value = e.target.value)}
            className="my-3 w-full rounded-md bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white placeholder:text-white"
          />
        </div>
        <div className="flex flex-col md:flex-row gap-4 mb-3">
        <div className="w-full">
          <select
            placeholder="Scholarship Type"
            value={scholarshipType.value}
            onInput={(e) => (scholarshipType.value = e.target.value)}
            className="mt-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
          >
            <option value="" disabled>
              Scholarship Type
            </option>
            <option className="text-black" value="full">Full Scholarship</option>
            <option className="text-black" value="partial">Partial Scholarship</option>
          </select>
        </div>
        <div className="w-full">
          <select
            placeholder="Category"
            value={category.value}
            onInput={(e) => (category.value = e.target.value)}
            className="mt-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
          >
            <option value="" disabled>
              Category
            </option>
            <option className="text-black" value="cat1">Category 1</option>
            <option className="text-black" value="cat2">Category 2</option>
            <option className="text-black" value="cat3">Category 3</option>
          </select>
        </div>
        </div>
        <div>
          <input
            type="text"
            placeholder="Program"
            value={program.value}
            onInput={(e) => (program.value = e.target.value)}
            className="my-3 w-full rounded-md bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white placeholder:text-white"
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Country"
            value={country.value}
            onInput={(e) => (country.value = e.target.value)}
            className="my-3 w-full rounded-md bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white placeholder:text-white"
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Institution"
            value={institution.value}
            onInput={(e) => (institution.value = e.target.value)}
            className="my-3 w-full rounded-md bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white placeholder:text-white"
          />
        </div>

        <div>
          <select
            value={period.value}
            onInput={handlePeriod}
            className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
          >
            <option value="" disabled>
              Period
            </option>
            {periods.map((period) => (
              <option className="text-black" value={period}>{period}</option>
            ))}
          </select>
        </div>

        {!!fee.value && (
          <div>
            <input
              type="text"
              value={`Fee:  ${fee.value}`}
              readOnly
              className="my-3 w-full rounded-full bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
            />
          </div>
        )}

        </div>
        <div className="w-full md:w-1/2">
        <div>
          <textarea
            className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
            onInput={(e) => (description.value = e.target.value)}
            rows="11"
            placeholder="Scholarship Description"
          >
            {description.value}
          </textarea>
        </div>
        </div>
        </div>
        <div className="flex justify-center gap-4">
        <button
              disabled={loading.value === true}
              type="button"
              onClick={handleBack}
              className={`mt-3 rounded-md mb-4 ${
                loading.value === true
                  ? "bg-gray-300"
                  : "bg-gray-900 hover: hover:bg-gray-500"
              } py-2 px-6 text-xl text-white ring-1 hover:text-white hover:bg-[#01936D] ring-gray-900 hover:ring-gray-500 font-semibold`}
            >
              Go Back
            </button>
            <button
              disabled={loading.value === true}
              type="button"
              onClick={handleSubmit}
              className={`mt-3 rounded-md mb-4 ${
                loading.value === true
                  ? "bg-gray-300"
                  : "bg-[#0E4D40] hover: hover:bg-[#01936D]"
              } py-2 px-6 text-xl text-white ring-1 hover:text-white hover:bg-[#01936D] ring-[#0E4D40] hover:ring-[#01936D] font-semibold`}
            >
              Submit
            </button>
        </div>
      </form>
      <Toaster />
    </div>
  );
}

export default CreateScholarshipForm;
