import { useSignal, useComputed } from "@preact/signals";
import { initiateAction } from "../../services/interntag-api";
import toast, { Toaster } from "react-hot-toast";
import { route } from "preact-router";

const categories = [
  "Administration, business and management",
  "Animals, land and environment",
  "Computing, ICT and Technology",
  "Construction and building",
  "Design, arts and crafts",
  "Education and training",
  "Engineering",
  "Facilities and property services",
  "Finance",
  "Hairdressing and beauty",
  "Healthcare",
  "Hospitality, catering and tourism",
  "Languages",
  "Legal and court services",
  "Manufacturing and production",
  "Performing arts and media",
  "Print and publishing, marketing and advertising",
  "Retail and customer services",
  "Science, mathematics and statistics",
  "Security, uniformed and protective services",
  "Social sciences and religion",
  "Social work and caring services",
  "Sport and leisure",
  "Transport, distribution and logistics",
];

const industries = [
  "Agriculture, plantations, other rural sectors",
  "Basic Metal Production",
  "Chemical industries",
  "Commerce",
  "Construction",
  "Education",
  "Financial services, professional services",
  "Food, drink, tobacco",
  "Forestry, wood, pulp and paper",
  "Health services",
  "Hotels, tourism, catering",
  "Mining (coal, other mining)",
  "Mechanical and electrical engineering",
  "Media, culture, graphical",
  "Oil and gas production, oil refining",
  "Telecommunications and Postal services",
  "Public service",
  "Shipping, ports, fisheries, inland waterways",
  "Textiles, clothing, leather, footwear",
  "Transport equipment manufacturing",
  "Utilities (water, gas, electricity)",
];

function CreateJobForm() {
  const title = useSignal("");
  const jobType = useSignal("");
  const category = useSignal("");
  // const jobRole = useSignal("");
  const industry = useSignal("");
  const country = useSignal("");
  const location = useSignal("");
  const description = useSignal("");
  const apply = useSignal("");
  const period = useSignal("");
  const fee = useSignal("");
  const loading = useSignal(false);
  const submitType = useSignal("");
  const submitValue = useSignal("");

  const periods = ["3 days", "1 week", "1 month"];
  const fees = ["5GHS", "10GHS", "35GHS"];

  const addJobRequest = useComputed(() => ({
    title: title.value,
    jobType: jobType.value,
    category: category.value,
    jobRole: title.value,
    industry: industry.value,
    country: country.value,
    location: location.value,
    description: description.value,
    instructions: apply.value,
    period: period.value,
    apply: submitValue.value,
  }));

  function handleBack() {
    route("/company/dashboard");
  }

  async function handleSubmit() {
    console.log(addJobRequest.value);
    loading.value = true;

    const result = await initiateAction("create-job", addJobRequest.value);
    loading.value = false;
    if (result) {
      console.log(result);
      if (result.ok) {
        toast.success(result.message, {
          position: "top-right",
        });
        setTimeout(() => {
          toast.remove();
          route("/company/dashboard");
        }, 2000);
      } else {
        toast.error(result.message, {
          position: "top-right",
        });
        setTimeout(() => {
          toast.dismiss();
        }, 2000);
      }
    }
  }

  function handlePeriod(e) {
    period.value = e.target.value;
    fee.value = fees[e.target.selectedIndex - 1];
  }

  return (
    <div className="container mx-auto p-2">
      <form>
        <Toaster />
        <div className="flex w-full flex-col md:flex-row justify-evenly gap-5">
          <div className="w-full md:w-1/2">
            <div>
              <input
                type="text"
                placeholder="Job Title"
                value={title.value}
                onInput={(e) => (title.value = e.target.value)}
                className="my-3 w-full rounded-md bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white placeholder:text-white"
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4 mb-3">
              <div className="w-full">
                <select
                  placeholder="Job Type"
                  value={jobType.value}
                  onInput={(e) => (jobType.value = e.target.value)}
                  className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
                >
                  <option value="" disabled>
                    Job Type
                  </option>
                  <option className="text-black" value="internship">
                    Internship
                  </option>
                  <option className="text-black" value="nss">
                    National Service (NSS)
                  </option>
                  <option className="text-black" value="job">
                    Job
                  </option>
                </select>
              </div>
              <div className="w-full">
                <select
                  placeholder="Category"
                  value={category.value}
                  onInput={(e) => (category.value = e.target.value)}
                  className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
                >
                  <option value="" disabled>
                    Category
                  </option>
                  {categories.map((category) => (
                    <option
                      key={category}
                      className="text-black"
                      value="category"
                    >
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 mb-3">
              <div className="w-full">
                <select
                  placeholder="Industry"
                  value={industry.value}
                  onInput={(e) => (industry.value = e.target.value)}
                  className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
                >
                  <option value="" disabled>
                    Industry
                  </option>
                  {industries.map((industry) => (
                    <option
                      key={industry}
                      className="text-black"
                      value="industry"
                    >
                      {industry}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="w-full">
                <select
                  placeholder="Role"
                  value={jobRole.value}
                  onInput={(e) => (jobRole.value = e.target.value)}
                  className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
                >
                  <option value="" disabled>
                    Role
                  </option>
                  <option className="text-black" value="Intern">
                    Intern
                  </option>
                  <option className="text-black" value="National Service">
                    National Service
                  </option>
                  <option className="text-black" value="Junior">
                    Junior
                  </option>
                  <option className="text-black" value="Mid-Level">
                    Mid-level
                  </option>
                  <option className="text-black" value="Senior">
                    Senior
                  </option>
                </select>
              </div> */}
            </div>
            <div className="flex flex-col md:flex-row gap-4 mb-3">
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Country"
                  value={country.value}
                  onInput={(e) => (country.value = e.target.value)}
                  className="my-3 w-full rounded-md bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white placeholder:text-white"
                />
              </div>
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Location"
                  value={location.value}
                  onInput={(e) => (location.value = e.target.value)}
                  className="my-3 w-full rounded-md bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white placeholder:text-white"
                />
              </div>
            </div>
            <div>
              <select
                value={period.value}
                onInput={handlePeriod}
                className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
              >
                <option value="" disabled>
                  Period
                </option>
                {periods.map((period) => (
                  <option className="text-black" value={period} key={period}>
                    {period}
                  </option>
                ))}
              </select>
            </div>

            {!!fee.value && (
              <div>
                <input
                  type="text"
                  value={`Fee:  ${fee.value}`}
                  readOnly
                  className="my-3 w-full rounded-full bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
                />
              </div>
            )}
          </div>
          <div className="w-full md:w-1/2">
            <div>
              <textarea
                className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white placeholder:text-white"
                onInput={(e) => (description.value = e.target.value)}
                rows="8"
                placeholder="Job Description"
              >
                {description.value}
              </textarea>
            </div>

            <div>
              <textarea
                className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white placeholder:text-white"
                onInput={(e) => (apply.value = e.target.value)}
                rows="10"
                placeholder="Application Instructions"
              >
                {apply.value}
              </textarea>
            </div>

            <div>
              <select
                value={submitType.value}
                onInput={(e) => (submitType.value = e.target.value)}
                className="my-3 w-full rounded-lg bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
              >
                <option value="" disabled>
                  Submission Type
                </option>
                <option className="text-black" value="Application Link">
                  Application Link
                </option>
                <option className="text-black" value="Application Email">
                  Application Email
                </option>
              </select>
            </div>

            {!!submitType.value && (
              <div>
                <input
                  type="text"
                  placeholder={submitType.value}
                  value={submitValue.value}
                  onInput={(e) => (submitValue.value = e.target.value)}
                  className="my-3 w-full rounded-full bg-transparent py-4 text-white px-4 text-sm ring-2 ring-white"
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-center gap-4">
          <button
            disabled={loading.value === true}
            type="button"
            onClick={handleBack}
            className={`mt-3 rounded-md mb-4 ${
              loading.value === true
                ? "bg-gray-300"
                : "bg-gray-900 hover: hover:bg-gray-500"
            } py-2 px-6 text-xl text-white ring-1 hover:text-white hover:bg-[#01936D] ring-gray-900 hover:ring-gray-500 font-semibold`}
          >
            Go Back
          </button>
          <button
            disabled={loading.value === true}
            type="button"
            onClick={handleSubmit}
            className={`mt-3 rounded-md mb-4 ${
              loading.value === true
                ? "bg-gray-300"
                : "bg-[#0E4D40] hover: hover:bg-[#01936D]"
            } py-2 px-6 text-xl text-white ring-1 hover:text-white hover:bg-[#01936D] ring-[#0E4D40] hover:ring-[#01936D] font-semibold`}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateJobForm;
