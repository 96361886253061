import Accordion from "../../components/accordion";
import CompanyLayout from "../../dependencies/company-layout";
import Helmet from "preact-helmet";
import { useComputed, useSignal, useSignalEffect } from "@preact/signals";
import { initiateAction } from "../../services/interntag-api";
import NoDataScreen from "../../components/noData";
import toast, { Toaster } from "react-hot-toast";

function CompanyDashboard() {
  let jobs = useSignal([]);

  let editing = useSignal(false);
  const description = useSignal("");
  const instructions = useSignal("");
  const jobId = useSignal("");

  const editJobRequest = useComputed(() => ({
    jobId: jobId.value,
    description: description.value,
    instructions: instructions.value,
  }));

  async function loadData() {
    console.log("running");
    const { ok, data } = await initiateAction("list-company-jobs");
    if (ok) {
      jobs.value = data;
    }
  }

  async function editData() {
    const { ok, message } = await initiateAction(
      "edit-job",
      editJobRequest.value
    );
    if (ok) {
      toast.success(message, {
        position: "top-right",
      });
    }
    handleEditingCancel();
    await loadData();
  }

  function handleInitEditing(initJobId, initInstructions, initDescription) {
    instructions.value = initInstructions;
    description.value = initDescription;
    jobId.value = initJobId;
    editing.value = true;
  }

  function handleEditingCancel() {
    // e.preventDefault();
    instructions.value = "";
    description.value = "";
    jobId.value = "";
    editing.value = false;
    // return;
  }

  useSignalEffect(() => {
    loadData();
  });

  return (
    <main>
      <Helmet title="Intern Tag | Company - Dashboard" />
      <CompanyLayout>
        <div className="pt-20" />
        <Toaster />
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="flex px-10 md:px-14 lg:px-20 py-4">
            <div
              className={` border px-6 py-1 rounded-full text-white bg-[#01936D] hover:bg-[#0E4D40] hover:text-white`}
            >
              Dashboard
            </div>
          </div>
          <div className="flex items-center gap-4 flex-col md:flex-row">
            <div className="hidden md:block">
              <label
                htmlFor="create-ad-modal"
                className="bg-[#0E4D40] hover:bg-[#01936D] px-4 py-3 rounded-md text-white"
              >
                Create AD
              </label>
            </div>
            <div className="flex mx-4 items-center justify-end">
              <div className="pb-1 md:pb-0 hidden md:block pr-1">Filter:</div>
              <div className="flex items-center gap-4 md:pr-10">
                <select
                  className="p-3 rounded-lg min-w-[20px] outline-none border "
                  type="text"
                  placeholder="Search"
                >
                  <option>Filter All</option>
                  <option>Internships</option>
                  <option>Scholarships</option>
                  <option>National Service (NSS)</option>
                  <option>Full Time</option>
                  <option>Actively Recruiting</option>
                  <option>Closed Recruiting</option>
                  <option>Recently Added</option>
                </select>
                <button className="bg-[#01936D] p-3 hover:bg-[#0E4D40] rounded-md text-white">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full relative min-h-screen lg:bg-gradient-to-b from-white via-white to-gray-200">
          <div className="hidden md:block ">
            <div className="flex fixed top-0 justify-center w-full">
              <div className="bg-gray-200 opacity-50 rounded-bl-lg rounded-br-lg px-10 pb-10 flex" />
            </div>
            <div className="flex fixed top-20 right-5 ">
              <div className="bg-gray-200 opacity-50 rounded-full p-5 flex" />
            </div>
            <div className="flex fixed bottom-40 left-5 ">
              <div className="bg-gray-200 opacity-50 rounded-lg p-7 flex" />
            </div>
          </div>
          <div className="flex flex-wrap items-center gap-6 px-10 lg:px-20 pt-2 pb-10">
            {jobs.value.map((job) => (
              <Accordion
                title={job.title}
                jobType={job.job_type}
                key={job.job_id}
                createDescriptionModal={`create-ad-description-${job.job_id}`}
                views={job.views}
                applications={job.applications}
                handleOpen={handleEditingCancel}
              />
            ))}

            {/* <Accordion createDescriptionModal={`create-ad-description`} /> */}
            {/* <Accordion createDescriptionModal={`create-ad-description`} /> */}
            {/* no data on page */}
            {jobs.value.length === 0 && (
              <div className="flex justify-center w-full">
                <NoDataScreen
                  title={`No Data to Show`}
                  content={`You have no ADs. To view insights and track applications,`}
                  link={`Create an AD`}
                  CreateCampaigns={`create-ad-modal`}
                />
              </div>
            )}
          </div>
        </div>

        {/* Modal Start */}
        <div>
          <input
            type="checkbox"
            id="create-ad-modal"
            className="modal-toggle"
          />
          <label htmlFor="create-ad-modal" className="modal cursor-pointer">
            <label className="modal-box relative" htmlFor="">
              <div>
                <a
                  href="/company/create-job"
                  className={`mt-3 block w-full rounded-full bg-black py-1 px-4 text-center text-sm text-white ring-1 ring-black`}
                >
                  Upload a Job
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className={`mt-3 block w-full rounded-full bg-black py-1 px-4 text-center text-sm text-white ring-1 ring-black`}
                >
                  Upload a Scholarship
                </a>
              </div>
            </label>
          </label>
        </div>
        {/* Modal Start for ad description*/}
        {jobs.value.map((job) => (
          <div key={job.job_id}>
            <input
              type="checkbox"
              id={`create-ad-description-${job.job_id}`}
              className="modal-toggle"
            />
            <label
              htmlFor={`create-ad-description-${job.job_id}`}
              className="modal cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x-lg fixed top-0 right-0 fill-white mx-5 my-2 hover:fill-red-500"
                xlinkTitle="Close"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
              <label
                className="modal-box w-[91%] max-w-[90%] relative"
                htmlFor=""
              >
                <div className="px-[10%] py-6">
                  <div className="text-2xl font-semibold">{job.title}</div>
                  {/* <div className="">Type: {job.job_role}</div> */}
                  <div className="">Company: {job.Company.name}</div>
                  <div className="">Status: {job.status}</div>
                  <div className="">Views: {job.views}</div>
                  <div className="">Applicants: {job.applications} </div>
                  <div className="">Duration: {job.period}</div>
                  {editing.value && (
                    <>
                      <div className="text-lg font-semibold pt-10">
                        Description
                      </div>
                      <div className="">
                        <textarea
                          className="my-3 w-full rounded-lg py-4 text-black px-4 text-sm ring-2 ring-blue placeholder:text-black"
                          onInput={(e) => (description.value = e.target.value)}
                        >
                          {description.value}
                        </textarea>
                      </div>
                      <div className="text-lg font-semibold pt-10">
                        Requirements
                      </div>
                      <div className="">
                        <textarea
                          className="my-3 w-full rounded-lg py-4 text-black px-4 text-sm ring-2 ring-blue placeholder:text-black"
                          onInput={(e) => (instructions.value = e.target.value)}
                        >
                          {instructions.value}
                        </textarea>
                      </div>
                      <div className="flex flex-col md:flex-row justify-center py-10 gap-4">
                        {/* <a
                      href={`/company/edit-job/${job.job_id}`}
                      className="bg-orange-500 text-center hover:bg-orange-700 px-6 py-2 text-white rounded-md"
                    >
                      Update Requirement
                    </a> */}
                        <button
                          className="bg-orange-500 text-center hover:bg-orange-700 px-6 py-2 text-white rounded-md"
                          onClick={editData}
                        >
                          Save
                        </button>
                        <button
                          className="bg-red-500 text-center hover:bg-red-700 px-6 py-2 text-white rounded-md"
                          onClick={handleEditingCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                  {!editing.value && (
                    <>
                      <div className="text-lg font-semibold pt-10">
                        Description
                      </div>
                      <div className="">{job.description}</div>
                      <div className="text-lg font-semibold pt-10">
                        Requirements
                      </div>
                      <div className="">{job.instruction}</div>
                      <div className="flex flex-col md:flex-row justify-center py-10 gap-4">
                        {/* <a
                      href={`/company/edit-job/${job.job_id}`}
                      className="bg-orange-500 text-center hover:bg-orange-700 px-6 py-2 text-white rounded-md"
                    >
                      Update Requirement
                    </a> */}
                        <button
                          className="bg-orange-500 text-center hover:bg-orange-700 px-6 py-2 text-white rounded-md"
                          onClick={(e) => {
                            e.preventDefault();
                            handleInitEditing(
                              job.job_id,
                              job.instruction,
                              job.description
                            );
                            return;
                          }}
                        >
                          Update Requirement
                        </button>
                        {/* <button className="bg-red-500 text-center hover:bg-red-700 px-6 py-2 text-white rounded-md">
                          Cancel Subscription
                        </button>
                        <button className="bg-[#01936D] text-center hover:bg-[#0E4D40] px-6 py-2 text-white rounded-md">
                          Extend Duration
                        </button> */}
                      </div>
                    </>
                  )}
                </div>
              </label>
            </label>
          </div>
        ))}
      </CompanyLayout>
    </main>
  );
}

export default CompanyDashboard;
