import { h } from 'preact'
import { Router } from 'preact-router'

import Home from '../routes/home'
import Login from '../routes/login'
import UserType from '../routes/user-type'
import Redirect from '../routes/redirect'
import CompanySignUp from '../routes/company-sign-up'
import StudentSignUp from '../routes/student-sign-up'
import CreateJob from '../routes/company/create-job'
import CreateScholarship from '../routes/company/create-scholarship'
import CompanyDashboard from '../routes/company/dashboard'
import CompanyProfile from '../routes/company/profile'
import StudentDashboard from '../routes/student/dashboard'
import StudentProfile from '../routes/student/profile'
import Recommended from '../routes/student/recommended'
import Trending from '../routes/student/trending'
import Bookmarks from '../routes/student/bookmarks'
import EditJob from '../routes/company/edit-job'

// Code-splitting is automated for `routes` directory

const App = () => (
  <Router>
    <Home path='/' />
    <Login path='/login' />
    <UserType path='/user-type' />
    <Redirect path='/redirect' />
    <CompanySignUp path='/company-sign-up' />
    <StudentSignUp path='/student-sign-up' />
    <CompanyDashboard path='/company/dashboard' />
    <StudentDashboard path='/student/dashboard' />
    <Trending path='/student/trending' />
    <StudentProfile path='/student/profile' />
    <Recommended path='/student/recommended' />
    <Bookmarks path='/student/bookmarks' />
    <CompanyProfile path='/company/profile' />
    <CreateJob path='/company/create-job' />
    <EditJob path='/company/edit-job/:jobId' />
    <CreateScholarship path='/company/create-scholarship' />
  </Router>
)

export default App
